import * as React from 'react';
import { withSubtheme } from '../../../../../../StarberryComponentsMui';
import PropertyGrid from '../../../../../PropertyGrid';
import ComponentLayout from '../../../../ComponentLayout';
import defaults from './defaults';

const SingleProperty = withSubtheme(({theme, properties, title, moreLabel, moreLinkUrl, titleLinkMoreTooltipProps, ...props}) => {
    return(
        <ComponentLayout
            theme={theme}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <PropertyGrid
                theme={theme}
                properties={properties}
                {...props}
            />
        </ComponentLayout>
    )
}, 'singleProperty', defaults)

export default React.memo(SingleProperty)
