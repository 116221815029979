export default {
    "props": {
        "initialStep": "stepOne",
        "initialFetch": false,
        "initialFetchProps": {},
        "apiCallFun": "getAvailabilitySlots",
        "customApiCall": {},
        "onlyModal": false,
        "openDefault": false,
        "valuationForm": {
            "stepOne": {
                nextStep: "StepTwo",
                formParams: {
                    form_name:'viewing_step_one'
                }
            },
            "stepTwo": {
                formParams: {
                    successMessage: "Valuation submitted Successfully",
                    cancelled: true,
                    form_name: 'valuation'
                }
            }
        },
        "cardModuleProps": {
            "elevation": 2,
        },
        "cardModuleTitleProps": {
            // "variant": "h5"
        },
        "cardModuleContentProps": {
            "component": "p",
            "variant": "bodyMedium"
        },
        "cardModuleButtonProps": {
            "color": "primary",
            "variant": "contained",
        },
        "actionCardDialogChildrenStyles": {
            "actionChildrenTitleProps": {
                "component": "h6",
                "variant": "bodyMedium",
            }
        },
    },
    "sx": (theme) => ({
        "& .wrapContainer": {
        },
        "& .wrapBox": {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: "none",
            [theme.breakpoints.down('md')]: {
                maxWidth: "600px",
                margin: "auto",
                textAlign: "center"
            },
            "& .MuiPaper-root": {
                marginBottom: 0
            },
            "& .makeStyles-root-5, & .makeStyles-root-6": {
                marginBottom: 0
            }
        },
        "& .wrapBoxProperty": {
            "padding": "1rem",
            "background": "#F4F4F4",
            textAlign: "left",
            "& .propertyresult-header": {
                "background": "#F4F4F4",
                paddingBottom: "0.5rem"
            },
            "& .propertyresult-address": {
                "color": theme.palette.primary.main
            },
            "& .propertyresult-price": {
                fontSize: "1rem",
                "& span": {
                    fontSize: "1.22rem"
                }
            }
        },
        // "& .formLogo": {
        //     "marginBottom": "1rem",
        //     "& img": {
        //         // "minWidth": "200px",
        //         "minHeight": "30px"
        //     }
        // },
        "& .formBack": {
            color: "#595959",
            fontSize: "1rem",
            marginBottom: "1rem",
            padding: "0 16px",
            cursor: "pointer",
            "& span": {
                marginRight: "10px"
            }
        },
        "& .fotmTitle": {
            // "marginBottom": "1rem",
            "fontSize": "2.250rem",
            "color": theme.palette.primary.main,
            padding: "0 16px",
        },
        "& .card-content": {
            marginBottom: "0",
            padding: "0 !important",
            width: "100%",
            maxWidth: "450px",
            ">div": {
                "marginBottom": 0,
                "paddingBottom": 0
            }
        },
        "& .content" : {
            // "background": "blue"
        },
        "& .card-content": {
            [theme.breakpoints.down('md')]: {
                // maxWidth: "362px"
            },
        },
        "& .globalForm-fieldContainer": {
            [theme.breakpoints.up('sm')]: {
                "& .myaccount-date-time-calendar": {
                    display: "initial"
                },
                "& .react-datepicker__time-container, & .react-datepicker__time-box":{
                    width: "100px"
                },
                "& .myaccount-date-time-calendar .react-datepicker .react-datepicker__navigation--next":{
                    right: "100px"
                },
                "& .react-datepicker__day": {
                    width: "2.6rem"
                },
            },
            // [theme.breakpoints.up('md')]: {
                
            // }
        },
        "& .viewingSuccess": {
            "padding": "3rem 1rem",
            textAlign: "center",
            maxWidth: "600px",
            margin: "auto",
            "& .formSuccessHeading": {
                "fontSize": "2.75rem",
                "color": theme.palette.primary.main,
                "marginBottom": "1.5rem"
            },
            "& .formSuccessSubHeading": {
                "fontSize": "1rem"
            },
            [theme.breakpoints.up('sm')]: {
                "padding": "8rem 1rem",
                "& .formSuccessHeading": {
                    "fontSize": "2.250rem",
                    "color": theme.palette.primary.main,
                    "marginBottom": "1.5rem"
                },
                "& .formSuccessSubHeading": {
                    "fontSize": "1.2rem"
                },
            }
        }
    }),
}
