export default {
    "props": {
        "Sanmugam": "sanmugam kathirvel",
        "header": {
            "sx": (theme) => ({
                "width": "calc(100% - 110px)",
                "& .propertyresult-address": {
                    // "fontWeight": theme.typography.fontWeightBold,
                },
            }),
        },
        "price": {
            "in": "header",
            "variant": "bodySmall",
        },
        "offerText": {
            "in": "header",
            "variant": "subtitle2",
            "component": "p"
        },
    },
    "sx": (theme) => ({
        "& .propertyresult-header": {
            background:"red"
        }
    }),
}
