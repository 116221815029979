import React from 'react'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { withSubtheme } from '../../StarberryComponentsMui';
import clsx from 'clsx';

import defaults from './ComponentLayoutDefaults';

const WrappedComponentLayout = withSubtheme((props) => {
    const {
        children,
        title,
        moreLabel,
        moreLinkUrl,

        className,     // combined classes
        // themeClasses,  // classes just for this subtheme from withThemeStyling() via withSubtheme()
        outerClassName,// classes for the outer element (the caller)

        containerProps,
        contentProps,
        titleProps,
        titleLinkMoreProps,
        titleLinkMoreTooltipProps,

        // ...rest
    } = props;

    return (
        <Paper
            classes={{root: clsx(outerClassName,className)}}
            {...containerProps}
        >
            {title && (
                <Box className="globalComponentLayout-titleContainer">
                    <Typography {...titleProps} classes={{root: "globalComponentLayout-title"}}>
                        {title}
                    </Typography>

                    {moreLabel &&
                     <Tooltip
                        {...titleLinkMoreTooltipProps}
                        TransitionComponent={Zoom}
                    >
                         <Typography {...titleLinkMoreProps} classes={{root: "globalComponentLayout-titleLinkMore"}}>
                             {moreLinkUrl ?
                              (
                                  <Link href={moreLinkUrl} color="inherit">
                                      {moreLabel}
                                  </Link>
                              ) : (
                                  moreLabel ?? undefined
                              )
                             }
                         </Typography>
                     </Tooltip>
                    }
                </Box>
            )}
            <Box {...contentProps} className="content">
                {children}
            </Box>
        </Paper>
    )
}, 'globalComponentLayout', defaults)

const ComponentLayout = ({className, ...props}) => (
    <WrappedComponentLayout outerClassName={className} className={className} {...props} />
);

export default React.memo(ComponentLayout);
