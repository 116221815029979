import React from "react";
import { useTheme } from '../core/StarberryComponentsMui';
import BookingForm from '../core/components/MyAccount/property/components/iframeBookViewing'

const BookingPage = () => {
    const theme = useTheme();
    return(
        <>
            <BookingForm />
        </>
    )
};

export default React.memo(BookingPage);
