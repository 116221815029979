import React from "react";
import { withSubtheme } from '../../../../../StarberryComponentsMui';
import GlobalForm from "../../../forms/";
import { collectFormValues, validateFormFields } from "../../../forms/utils";
import { useFormState } from "../../../../../services";
import reportFaultFields from "../../../forms/Forms/valuation";
import { trackValuation } from "../../../helper/eventTracking";

const DialogChildren = withSubtheme((props) => {
    const { className } = props

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }

    const onSubmit = event => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false) {
            let params = {
                form_name: "valuation",
                // step: step,
                successMessage: "Valuation Posted Successfully",
                // ...actionParams.formParams,
                trackData: () => {
                    trackValuation()
                }
            }

            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
            />
        </div>
    )
}, 'dialogChildren')

export default DialogChildren
