import React, { useEffect, useState} from 'react';
import { isEmpty } from 'lodash';
import { querystring, apiUrl, getToken } from '../../../../../services/store/utils';
import { withSubtheme } from '../../../../../StarberryComponentsMui';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import ComponentLayout from '../../../ComponentLayout';
import DialogChildren from './dialogChildren';
import DialogChildrenCustom from './dialogChildrenCustom';
import DialogChildrenStepOne from './dialogChildrenStepOne';
import DialogChildrenStepTwo from './dialogChildrenStepTwo';
import { FormStoreProvider, useFormState, usePropertyActionState } from "../../../../../services";
import CircularProgress from '../../../progress/CircularProgress'
import { getCall } from "../../../../../services/store/api"
import Logo from '../../../Logo/LogoSecondary';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SingleProperty from './singleProperty';
import axios from 'axios'

import defaults from './defaults';

const getPropertyId = () => {
    const searchParams = new URLSearchParams(typeof window !== "undefined" ? document.location.search : '');
    return searchParams.get('propertyId');
}

const SimpleModuleWrap = (props) => {
    const {
        propertyId,
        properties,
        className,
        title,
        moreLabel,
        moreLinkUrl,
        titleLinkMoreTooltipProps,
        openDefault,
        setShow,

        cardModuleProps,
        initialStep,
        // initialFetch,
        // initialFetchProps,
        customApiCall,
        actionCardDialogChildrenStyles,
    } = props;

    const { state, services } = useFormState()
    const { services: actionServices } = usePropertyActionState()
    const step = state?.step || state?.formState?.step || initialStep || '';
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const successForm = (state.success && !state.error)

    const [open, setOpen] = React.useState(openDefault || false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        value.preventDefault();
        services.resetForm();
        if (state?.data?.postAction === 'add') {
            actionServices.updatePropertyStatus({...state.data, key:'worksOrders.open_items'});
        }
        setOpen(false);
        if (setShow) {
            setShow(false);
        }
    };

    const handleBack = () => {
        let backStep = 'stepOne';
        if (step == 'actionCustomSlot')
            backStep = 'stepTwo';

        services.changeFormState({step: backStep})
    }

    React.useEffect(() => {
        // Calling the api
        if (step === 'stepTwo') {
            let params = { property_id: propertyId}
            if (!isEmpty(customApiCall)) {
                services.changeFormState({loading: true})
                let paramsAlt = {type: params.property_type, postcode: params.postcode.replace(/ /g, '')}
                getCall(`${customApiCall.url}?${querystring(paramsAlt)}`).then((res) => {
                    if (res.status === 200) {
                        if (isEmpty(res.data.slots)) {
                            services.changeFormState({
                                loading: false,
                                slots: [],
                                error:false,
                                message: ''/*err.response.status*/,
                                step: 'actionCustomSlot',
                                introMessage: ``
                            })
                        } else {
                            services.changeFormState({loading: false, preLoaded: true, slots: res.data,  message:''})
                        }

                    } else {
                        services.changeFormState({loading: false, slots: [], error:true, message: res.status})
                    }
                }).catch(function(err) {
                    console.log("Error:", err.response);
                    // To display error message
                    // services.changeFormState({loading: false, slots: [], error:true, message: 'Please enter a valid postcode'/*err.response.status*/, step: 'stepOne'})
                    services.changeFormState({
                        loading: false,
                        slots: [],
                        error:false,
                        message: ''/*err.response.status*/,
                        step: 'actionCustomSlot',
                        introMessage: `Sorry, there were no appointments found for this property. To arrange your valuation, please submit your request and we will do our best to accomodate you`
                    })
                })
            } else {
                services.getOpenAvailabilitySlots(params)
            }
        }
        // if (initialFetch)
        //     services.getAvailabilitySlotsByPostcode(initialFetchProps || {})
    }, [step]) // eslint-disable-line react-hooks/exhaustive-deps

    let dialogContent = '';
    if (state.loading) {
        dialogContent = <CircularProgress />
    } else {
        if (step === 'stepOne') {
            dialogContent = <DialogChildrenStepOne
                handleClose={handleClose}
                propertyId={propertyId}
            />
        } else if (step === 'stepTwo') {
            dialogContent = <DialogChildrenStepTwo
                handleClose={handleClose}
                propertyId={propertyId}
            />
        } else if (step === 'actionCustomSlot') {
            dialogContent = <DialogChildrenCustom
                handleClose={handleClose}
                propertyId={propertyId}
                actionCardDialogChildrenStyles={actionCardDialogChildrenStyles}
            />
        } else {
            dialogContent = <DialogChildren handleClose={handleClose} />
        }
    }

    if (!propertyId) {
        return (
            <p>Loading...</p>
        )
    }

    return(
        <ComponentLayout
            className={className}
            title={title}
            moreLabel={moreLabel}
            moreLinkUrl={moreLinkUrl}
            titleLinkMoreTooltipProps={titleLinkMoreTooltipProps}
        >
            <Container maxWidth="md" className='wrapContainer'>
                <Card
                    classes={{ root: 'wrapBox'}}

                >
                    {!!successForm ? (
                        <Grid container spacing={2}>
                            <div className='viewingSuccess'>
                                <Typography component="h1" variant="h5" className="formSuccessHeading" >
                                    Your viewing appointment has been successfully booked.
                                </Typography>
                                <Typography component="h1" variant="body2" className="formSuccessSubHeading" >
                                    A representative from our team will be in touch with you shortly.
                                </Typography>
                            </div>
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {(step !== 'stepOne') && (
                                    <Typography component="h4" variant="h5" className="formBack" onClick={() => handleBack()}>
                                        <span>&#60;</span> Back
                                    </Typography>
                                )}
                                <Typography component="h1" variant="h5" className="fotmTitle" >
                                    Book a viewing
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <CardContent className='card-content'>
                                    {dialogContent}
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Card
                                    classes={{ root: 'wrapBoxProperty'}}
                                >
                                    <SingleProperty
                                        properties={properties}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </Card>
            </Container>
        </ComponentLayout>
    )
}

const SimpleModule = withSubtheme((props) => {
    
    return (
        <FormStoreProvider>
            <SimpleModuleWrap {...props} propertyId={props.propertyId} />
        </FormStoreProvider>
    )
}, 'bookingWidget', defaults)

const BookViewing = () => {
    const [loading, setLoading] = useState();
    const [properties, setProperties] = useState();
    const propertyId = getPropertyId();
    useEffect(() => {
        const getData = async() => {
            const authInstanceFun = () => {
                const token =  process.env.GATSBY_API_KEY;
                return axios.create({
                  baseURL: apiUrl(),
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
            };

            const graphqlQuery = {
                "query":`query property {
                    properties(where:{crm_id: "${propertyId}"}) {
                        address
                        bathroom
                        bedroom
                        building
                        crm_id
                        department
                        display_address
                        id
                        images
                        imagetransforms
                        price
                        price_qualifier
                        reception
                        search_type
                        slug
                        status
                        title
                    }
                  }
                    `,
            };
            setLoading(true)
            const response = authInstanceFun().post('/graphql', graphqlQuery)
            .then((res) =>  {
                setLoading(false);
                if (res?.data?.data?.properties)
                setProperties(res?.data?.data?.properties)
            });
        }
        getData();
    }, [propertyId])

    if (!propertyId || !properties) {
        return(<p>Loading...</p>)
    }

    return (
        <>
            <SimpleModule propertyId={propertyId}  properties={properties}/>
        </>
    )
}

export default BookViewing
