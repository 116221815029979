/* eslint-disable import/first */

/**
 * @copyright © 2020, Starberry Ltd
 */

import { LogoOrLogotype } from './index';

export const Logotype = (props) => (
    LogoOrLogotype({type1: 'logoSecondary', type2: 'logo', type2: 'logotype', ...props})
);

Logotype.propTypes = {
//    wrapper: PropTypes.bool
};

Logotype.defaultProps = {
//    wrapper: true
};

export default Logotype;